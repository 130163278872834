/** @jsx jsx */
import { jsx } from 'theme-ui'

// styles
const pageStyles = {
  color: "text",
  fontFamily: "body",

  padding: "2.5rem 2rem 1rem",
  margin: "0 auto",
  maxWidth: "30rem",
}
const headingStyles = {
  color: "secondaryText",
  fontSize: 5,
  marginTop: 0,
  marginBottom: 24,
}
const articleStyles = {
  marginBottom: 48,
}
const paragraphStyles = {
  color: "text",
  fontSize: 1,
  lineHeight: "body",

  marginBottom: 16,
}
const linkStyle = {
  color: "primary",
  fontWeight: "bold",
  textDecoration: "none",
}
const footerStyle = {
  color: "#888",
  fontSize: 1,
}

// markup
const IndexPage = () => {
  return (
    <main sx={pageStyles}>
      <title>Mosaic Dreamers - Apps you'll love</title>
      <h1 sx={headingStyles}>
        MOSAIC DREAMERS
      </h1>
      <article sx={articleStyles}>
        <section>
          <p sx={paragraphStyles}>
            We are an app development and consulting team based in Canada <span role="img" aria-label="canada flag">🇨🇦</span>.
            We love building apps to solve various problems.
          </p>
          
          <p sx={paragraphStyles}>
            Checkout our latest project, <a sx={linkStyle} href={`https://getmanabu.app`}>Manabu</a>.
          </p>
        </section>

        <section>
          <h3 sx={{
            ...headingStyles,
            fontSize: 3
          }}>
            Contact Us
          </h3>

          <p sx={paragraphStyles}>
            We'd love to hear from you. <a sx={linkStyle} href={`mailto:hello@mosaicdreamers.com`}>Say Hi <span role="img" aria-label="wave">👋</span></a>.
          </p>
        </section>
      </article>

      <footer sx={footerStyle}>
        © Mosaic Dreamers Inc.
      </footer>
    </main>
  )
}

export default IndexPage
